.edit-question-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.edit-question-container label {
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
}

.edit-question-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
  transition: border-color 0.3s;
}

.correct-checkbox-label {
  margin-left: 20px;
}

.option-list-item {
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.delete-option-btn {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.delete-option-btn:hover {
  background-color: #45a049;
}
