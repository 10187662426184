.sign-up-container {
  max-width: 400px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.dark-mode .sign-up-container {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}
.sign-up-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
}

.sign-up-container label {
  font-size: 14px;
  color: #8c8585;
  margin-bottom: 5px;
}

.sign-up-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}
.dark-mode .sign-up-container input {
  background-color: #272727;
  border: none;
  color: white;
}
.sign-up-container input:focus {
  outline: none;
  border-color: #5b9dd9;
}
