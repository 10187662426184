.modal-close {
  text-align: right;
}
.dark-mode-toggle {
  cursor: pointer;
  font-size: 24px;
}
.dark-mode .dark-mode-toggle svg {
  fill: white;
}
.light-mode .dark-mode-toggle svg {
  fill: black;
}
.navbarRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.navbtn {
  display: flex;
  align-items: center;
  background-color: transparent;
  text-decoration: none;
  border: 1px solid currentColor;
  cursor: pointer;
  padding: 2px 10px;
}
.navbtn svg {
  margin-left: 5px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #333;
}
.light-mode .navbar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.navbar a {
  text-decoration: none;
}
.light-mode .navbar a {
  color: black;
}
.dark-mode .navbar a {
  color: white;
}
.dark-mode .navbar {
  border-bottom: 1px solid #ccc;
  background-color: #1e1e1e;
}

.logout-btn {
  cursor: pointer;
  padding: 5px 10px;
  background: none;
  border: 1px solid #000000;
  border-radius: 5px;
}
.dark-mode .logout-btn {
  color: white;
  border-color: white;
}
