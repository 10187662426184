.single-event-container {
  width: 95%;
  max-width: 1240px;
  margin: 0 auto;
}
.single-event-details {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  margin: 0 auto;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.eventDetails h4 {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 0px;
}
.single-event-details-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.dark-mode .single-event-details {
  background-color: #1e1e1e;
}
.single-event-title-span {
  text-align: left;
  font-weight: 400;
  font-size: 0.865rem;
}
.single-event-title {
  font-size: 1.35rem;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.single-event-details-left {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 10px;
}
.single-event-details-right {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  width: 30%;
  min-width: 30%;
}
.single-event-details-right svg {
  width: 40px;
  height: 40px;
}
.single-event-details-right button {
  margin-left: 10px;
}
.dark-mode .single-event-status-show.event-active-span {
  color: white;
}
.dark-mode .single-event-status-show {
  color: white;
  background-color: #2c2c2c;
}
.single-event-status-show {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 99999px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  margin-left: 5px;
  padding: 0px 8px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.single-event-status-show.event-active-span {
  color: black;
  border: 1px solid #4caf50;
}
.event-active-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.active-dot-green {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #4caf50;
  border-radius: 100%;
  margin-right: 5px;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.questionsListParent {
  margin: 20px 0;
}
.questionsListinnner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.questionsListLeft {
  width: 25%;
  /* max-width: 400px; */
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.dark-mode .questionsListLeft,
.dark-mode .questionsListRight {
  background-color: #1e1e1e;
}
.questionsListRight {
  width: 75%;
  margin-left: 20px;
  background-color: white;
  /* max-width: 400px; */
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.questionsListLeftTitle {
  font-size: 0.85rem;
  margin-bottom: 20px;
  display: block;
}

.questionsleftsinglequestionparent {
  border: 1px dashed #1e1e1e;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0;
}
.dark-mode .questionsleftsinglequestionparent {
  border-color: #383838;
}
.questionsleftquestion {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  margin-bottom: 20px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.86rem; /* Prefix required. Even Firefox only supports the -webkit- prefix */
}
.questionsleftquestion:hover {
  color: #1e1e1e;
}
.dark-mode .questionsleftquestion {
  color: #8c8585;
}
.dark-mode .questionsleftquestion:hover {
  color: white;
}
.question-action-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addQuestionSVG * {
  fill: #8c8585;
  color: #8c8585;
}
.Addquestionsvgparent {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: clamp(100px, 1.8rem, 150px);
}
.Addquestionsvgparent:hover svg path {
  fill: #1e1e1e;
}
.dark-mode .Addquestionsvgparent:hover svg path {
  fill: white;
}

.deleteQuestionSvgParent {
  text-align: right;
}
.deleteQuestionSVG {
  fill: #8c8585;
  border-radius: 99999px;
  /* padding: 2px; */
  cursor: pointer;
}
.dark-mode .deleteQuestionSVG svg,
.dark-mode .deleteQuestionSVG path {
  fill: #8c8585;
  stroke: #8c8585;
}

.QuestRsultVisible,
.QuestRsultVisible:hover,
.dark-mode .QuestRsultVisible,
.dark-mode .QuestRsultVisible:hover {
  background-color: rgb(244, 102, 102);
}

.presenterBtn {
  border: 1px solid #3300cc;
  background-color: transparent;
  color: #3300cc;
  margin-right: 0;
}
.presenterBtn:hover {
  background-color: #3300cc;
  color: white;
}

.gobacktoAllEvents {
  width: max-content;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}
.gobacktoAllEvents > svg {
  margin-right: 4px;
}

.dark-mode .gobacktoAllEvents {
  color: #bb86fc;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #4caf50;
  }
  50% {
    background-color: #4caf50;
    box-shadow: 0 0 40px #4caf50;
  }
  100% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #b7dfb9;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #4caf50;
  }
  50% {
    background-color: #4caf50;
    box-shadow: 0 0 40px #4caf50;
  }
  100% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #b7dfb9;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #4caf50;
  }
  50% {
    background-color: #4caf50;
    box-shadow: 0 0 40px #4caf50;
  }
  100% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #b7dfb9;
  }
}

@keyframes glowing {
  0% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #4caf50;
  }
  50% {
    background-color: #4caf50;
    box-shadow: 0 0 40px #4caf50;
  }
  100% {
    background-color: #b7dfb9;
    box-shadow: 0 0 3px #b7dfb9;
  }
}
