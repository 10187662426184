.live-event-with-question {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.live-event-with-question .attempted.options-li.correct {
  border-color: #67ce67;
  background-color: #4caf50;
  color: white;
}
.live-event-with-question .options-li.correct {
  background-color: rgba(16, 187, 53, 0.08);
  color: unset;
}

.live-event-with-question ul {
  width: 80%;
  margin: 20px auto;
  display: block;
}
.live-event-with-question h3 {
  text-align: center;
  margin: 0;
}
.live-event-with-question
  label
  input.correctInput[type="radio"]:checked::before {
  background-color: black;
}
.submit-btn-container {
  margin-bottom: 20px;
}
.live-event-with-question .options-li.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.resultCubes {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.correctCube {
  background-color: #4caf50;
  border-radius: 5px;
  max-width: max-content;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0 10px;
}
.correctCube svg,
.wrongCube svg,
.notAttemptedCube svg {
  margin-left: 5px;
  width: 1rem;
  height: 1rem;
}
.wrongCube {
  margin: 0 10px;
  background-color: #eb5545;
  border-radius: 5px;
  max-width: max-content;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
}

.notAttemptedCube {
  margin: 0 10px;
  background-color: #c5c5c5;
  border-radius: 5px;
  max-width: max-content;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  color: black;
}
