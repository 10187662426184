body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s, color 0.3s;
}

.dark-mode {
  /* background-color: #333;  */
  background-color: #121212;
  color: white; /* Dark mode text color */
}

.light-mode {
  background-color: #f0f0f0; /* Light mode background color */
  color: black; /* Light mode text color */
}

/* Add any additional styles for other components or classes */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dark-mode .button {
  background-color: #bb86fc;
  color: black;
}
.dark-mode .button:hover {
  background-color: #ad6dfb;
}
.button {
  background-color: #3300cc;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #2f009a;
}
.stop-icon {
  fill: rgb(244, 102, 102);
  cursor: pointer;
}
.dark-mode .start-icon {
  fill: #bb86fc;
}
.start-icon {
  fill: #3300cc;
  cursor: pointer;
}
.action-btn {
  /* margin-left: 10px; */
  margin: 0;
  display: inline-block;
}
.option-list-item {
  margin: 10px 0;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.animate-spin {
  animation: spin 1s linear infinite;
  margin-left: 5px;
}
@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

/* .table {
  margin: 10px 0;
  margin-bottom: 30px;
}

.table td {
  text-align: center;
} */
