.add-question-main {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.add-question-main .main-heading {
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: center;
  display: block;
  color: #8c8585;
}

.add-question-input-parent {
  display: flex;
  flex-direction: column;
}
.form-label {
  font-size: 12px;
  color: rgb(140, 133, 133);
  margin-bottom: 5px;
  display: block;
  font-weight: 600;
}

.form-input {
  /* width: 100%; */

  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
  transition: border-color 0.3s;
}

.dark-mode .form-input {
  border: 1px solid #252525;
  background-color: #272727;
  color: white;
}

.form-input:focus {
  outline: none;
  border-color: #5b9dd9;
}
.dark-mode .form-input:visited {
  background-color: #272727;
}
.sub-heading {
  font-size: 20px;
  margin-bottom: 10px;
}
.options-input-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.option-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
}
.select-correct-option {
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.correct-checkbox-label {
  /* margin-left: 20px; */
  display: none;
}

.form-checkbox {
  margin-right: 5px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-button,
.delete-option-btn {
  background-color: #4caf50;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.form-button:hover,
.delete-option-btn:hover {
  background-color: #45a049;
}

.isCorrectsvg {
  fill: lightgray;
  cursor: pointer;
  border-radius: 999999px;
  height: 18px;
  width: 18px;
}
.dark-mode .isCorrectsvg,
.dark-mode .deleteOptionSvg {
  fill: #383838;
}

.isCorrectsvg:hover,
.isCorrectsvg.selected {
  fill: #45a049;
}
.deleteOptionSvg {
  width: 20px;
  margin-left: 5px;
  fill: lightgray;
  cursor: pointer;
}
.deleteOptionSvg:hover {
  fill: red;
}
