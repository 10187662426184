.poll-progress-bar-container {
  height: 1.25rem;
  width: 100%;
  /* background-color: #f0f0f0; */
  border-radius: 5px;
  margin: 5px 0;
  overflow: hidden;
  display: flex;
  align-items: center; /* Hide overflow to prevent percentage label from showing outside the container */
}
.optionParent {
  margin-bottom: 20px;
  max-height: 100%;
  width: 95%;
  margin: 30px auto;
}
.poll-progress-bar {
  transition: width 0.5s ease; /* Smooth transition effect for width changes */
  color: #fff;
  text-align: center;
  height: 100%;
  border-radius: 5px;
}
.pollOptionName {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  font-size: 1.2rem;
  font-weight: 500;
}
.correctpolloptionicon {
  margin-left: 5px;
  border: 2px solid #4caf50;
  border-radius: 99999px;
  /* padding: 4px; */
}

.percentage-label {
  display: block;
  margin-left: 5px;
}

.noactivepollcontainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #1a73e8;
  min-height: 100vh;
}
.noactivepollheading {
  color: white;
}
.noactivepollinner {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
}
.nopollleft {
  max-width: 50%;
  text-align: right;
}
.nopollright {
  max-width: 50%;
  text-align: left;
  padding-left: 8rem;
  position: relative;
}
/* .nopollright ::before {
  content: "";
  position: absolute;
  background-color: #fff;
  top: 20%;
  bottom: 20%;
  width: 0.05rem;
  left: 4rem;
} */

.qr-code-container {
  border-radius: 0.4rem;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  width: 19.5rem;
  height: 19.5rem;
}
.qr-code-container img {
  vertical-align: middle;
}
.nopollleft h1 {
  color: white;
  font-size: 3rem;
  line-height: 1;
  margin-bottom: 1.125rem;
}
.pollquestionparent {
  background-color: rgba(0, 0, 0, 40%);
  color: white;
  border-radius: 4px;
  padding: 1rem 10px;
  flex: 1;
  margin-bottom: 30px;
}
.pollquestionName {
  display: block;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 1rem;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.pollParent {
  display: flex;
  flex-direction: row;
  background-color: #1a73e8;
  min-height: 100vh;
}
.pollquestionleft {
  margin: 0 1rem;
  margin-top: 50px;
  color: white;
}
.pollquestionleft h3 {
  text-align: center;
}
.pollquestionright {
  flex: 1;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}
.pollquestionleft .qr-code-container {
  width: 10.5rem;
  height: 10.5rem;
}
.pollquestionrightnavbar {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activepolltitle {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 400;
}
.activepolltitle svg {
  margin-right: 5px;
}
.pollusers {
  color: white;
  display: flex;
  align-items: center;
}
.pollusers p {
  margin-right: 5px;
}
