.login-container {
  max-width: 400px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.dark-mode .login-container {
  background-color: #1e1e1e;
}
.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.login-container label {
  font-size: 12px;
  margin-bottom: 8px;
  color: #8c8585;
}

.login-container input {
  /* width: 100%; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}
.dark-mode .login-container input {
  background-color: #272727;
  border-color: #272727;
  color: white;
}
.login-container input:focus {
  outline: none;
  border-color: #5b9dd9;
}

.login-container button {
  border: none;
  font-size: 16px;
  transition: background-color 0.3s;
}
