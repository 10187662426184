.event-tag {
  text-align: center;
  /* border-bottom: 1px solid #c5c5c5; */
  padding: 1rem 0;
  font-weight: 400;
  font-size: 1.2rem;
  margin: 0.865rem 0;
  padding: 0;
}
.dark-mode .event-tag {
  color: #8c8585;
  border-color: #8c8585;
}
.event-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
}
.event-title h1 {
  margin: 0.865rem 0;
  font-size: 1.5rem;
  padding: 0;
}
.event-title p {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.loading {
  font-size: 18px;
  color: #333;
  padding: 20px;
  background-color: #f2f2f2;
}

.not-live {
  text-align: center;
  font-size: 18px;
  color: #ff0000;
  padding: 20px;
  background-color: #ffe6e6;
  border-radius: 10px;
}
.dark-mode .not-live {
  color: rgb(242, 104, 91);
  background-color: rgba(242, 104, 91, 0.08);
}
.live-event-with-question {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  background-color: white;
}
.dark-mode .live-event-with-question {
  background-color: #1e1e1e;
}
.live-event-with-question h3 {
  /* color: #009933; */
  color: black;
  padding: 10px 20px;
  padding-top: 1.5rem;
  margin-bottom: 0;
  /* background-color: #e6ffe6; */
}
.live-event-with-question .no-questions-live {
  text-align: center;
  margin: 15px 0px;
}
.dark-mode .live-event-with-question h3 {
  color: white;
  border: none;
}
.live-event-with-question ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.live-event-with-question form {
  padding: 20px;
}
.live-event-with-question .options-li {
  margin-bottom: 1rem;
  font-size: 1rem;
  /* color: #009933; */
  color: black;
  border-radius: 10px;
  padding: 10px;
  background-color: #e6ffe6;
  border: 1px solid #67ce67;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dark-mode .live-event-with-question .options-li {
  color: white;
  background-color: rgba(16, 187, 53, 0.08);
}
.live-event-with-question .options-li:hover {
  background-color: #67ce67;
}
.live-event-with-question #nohover:hover {
  cursor: not-allowed;
  background-color: rgba(16, 187, 53, 0.08);
}

label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.live-event-with-question .options-li.selected-option {
  background-color: #67ce67; /* Background color for the selected option */
}
.live-event-with-question label input[type="radio"] {
  cursor: pointer;
  appearance: none; /* Remove default appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px; /* Set the size of the radio button */
  height: 15px;
  outline: 1px solid black; /* Set border color */
  border-radius: 50%; /* Make it round */
  margin-right: 10px; /* Add some space between the radio button and label */
  position: relative;
  top: 0px;
}
.live-event-with-question label input[type="radio"]:checked {
  outline: 1px solid white;
}
/* Style the radio button when checked */
.live-event-with-question label input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;

  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.8s;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #fff;
  }
  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: black;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: #67ce67;
  }
  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: #fff;
  }
}

.submit-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.submit-btn {
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 1.1rem;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.submit-btn:disabled {
  cursor: not-allowed;
  background-color: #c5c5c5;
  color: black;
}

.progress-bar-container {
  height: 15px;
  border-radius: 10px;
  background-color: #ddd; /* Lighter color */
  overflow: hidden;
  position: relative;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  border-radius: 10px;
  width: 0; /* Initialize width to 0 */
  background-color: #4caf50; /* Darker color */
  transition: width 1s linear; /* Transition for smooth width change */
}

.timer-seconds {
  font-size: 1rem;
  padding-top: 1rem;
}
.live-event-with-question .options-li.correct {
  border-color: #67ce67;
  background-color: #4caf50;
  color: white;
}
.live-event-with-question .options-li.correct.selected {
  background-color: #67ce67;
}
.live-event-with-question .options-li.wrong {
  background-color: #eb5545;
  border-color: #eb5545;
  color: white;
}
.live-event-with-question #attempted.correct {
  background-color: #67ce67;
}
#correctBtn {
  background-color: #4caf50;
  color: white;
}
#wrongBtn {
  background-color: #eb5545;
  color: white;
}
#root
  > span
  > div.live-event-with-question
  > form
  > ul
  > li.options-li.show-result.correct
  > label
  > input[type="radio"] {
  background-color: none;
}
#root
  > span
  > div.live-event-with-question
  > form
  > ul
  > li.options-li.show-result.correct.selected
  > label
  > input[type="radio"] {
  background-color: black;
}
#root
  > span
  > div.live-event-with-question
  > form
  > ul
  > li.options-li.show-result.wrong
  > label
  > input[type="radio"] {
  background-color: white;
}
.icon-image {
  border: 2px solid currentColor;
  border-radius: 100%;
}

.announcement-parent {
  margin: 20px auto;
}
.client-announcement-title svg {
  margin-right: 5px;
}
.client-announcement-title {
  background-color: #3300cc;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dark-mode .client-announcement-title {
  background-color: #bb86fc;
  color: black;
}

.client-announcement {
  font-size: 1rem;
  font-weight: 600;
  padding: 10px 20px;
}
