/* EventList.css */
.event-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.event-table th,
.event-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.event-name-button {
  background-color: transparent;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.event-name-button:hover {
  text-decoration: none;
  color: darkblue;
}

.eventDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.event-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.dark-mode .event-card {
  background-color: #1e1e1e;
  border-color: transparent;
}

.event-card h3 {
  margin-bottom: 10px;
}

.event-card a {
  color: #3300cc;
  font-weight: 600;
  font-size: medium;
  text-decoration: none;
}
.dark-mode .event-card a {
  color: #bb86fc;
}

.events-list-container {
  padding-bottom: 100px;
  padding-top: 30px;
  margin: 0 20px;
}

.event-add-btn {
  margin: 20px 0px;
}
.event-card-menu {
  color: #909090;
  /* color: #cccccc; */
  /* color: #333333; */
}

.single-event-container {
  padding-bottom: 100px;
  padding-top: 30px;
  margin: 0 20px;
}

/* Table Styles */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Table Header Styles */
.table th {
  background-color: #f2f2f2;
  border: 1px solid #dddddd;
  padding: 12px;
  text-align: left;
  font-weight: bold;
}

/* Table Row Styles */
.table td,
.table th {
  border: 1px solid #dddddd;
  padding: 12px;
}

/* Alternating Row Colors */
.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Hover Effect on Rows */
.table tbody tr:hover {
  background-color: #e6f7ff;
}

.eventCopyIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.eventCopyIcon svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 99999px;
  padding: 5px;
  cursor: pointer;
}
.dark-mode .eventCopyIcon svg {
  background-color: #383838;
}
.eventCopyIcon svg:hover {
  background-color: #3300cc;
  fill: white;
}
.dark-mode .eventCopyIcon svg:hover {
  background-color: #bb86fc;
  fill: black;
}

.delete-quiz-span-title {
  color: #8c8585;
  font-size: 0.864rem;
}
.delete-button {
  background-color: rgb(244, 102, 102);
  color: black;
}
.delete-button:hover {
  background-color: rgb(244, 102, 102);
  color: black;
}

.announcement-msg {
  font-size: larger;
  display: block;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 20px;
}
.announcement .event-card-menu {
  font-size: 0.85rem;
}
.eventCardLinks {
  display: flex;
  justify-content: space-between;
}
/* Responsive Table Styles */
@media (max-width: 768px) {
  .table {
    display: block;
  }

  .table thead {
    display: none;
  }

  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .table td {
    text-align: right; /* Adjust as needed */
  }
}
