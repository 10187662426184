/* TabComponent.css */

.tab-container {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 10px;
  overflow: hidden;
}

.tabs {
  display: flex;
  background-color: #f0f0f0;
  border-bottom: 2px solid #ddd;
}
.dark-mode .tabs {
  background-color: #1e1e1e;
  border-bottom: 2px solid #272727;
}
.tabs button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 15px 20px;
  flex: 1;
  transition: background-color 0.3s ease;
  font-size: 16px;
  font-weight: bold;
  color: #666;
}

.tabs button:hover {
  background-color: #ddd;
}
.dark-mode .tabs button:hover {
  background-color: #272727;
  color: white;
}
.tabs button.active {
  background-color: #fff;
  border-bottom: 4px solid #bb86fc;
  color: #333;
}
.dark-mode .tabs button.active {
  background-color: #272727;
  color: white;
}
.tab-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.dark-mode .tab-content {
  background-color: #1e1e1e;
}

.table-container {
  overflow-x: auto;
  margin: 30px 0;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}
.responsive-table th {
  font-size: 14px;
  word-wrap: break-word;
}
.responsive-table th,
.responsive-table td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}
.dark-mode .responsive-table th,
.dark-mode .responsive-table td {
  border-color: #383838;
}
.responsive-table th {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #333;
  text-transform: capitalize;
  cursor: pointer;
}
.dark-mode .responsive-table th {
  background-color: #272727;
  color: white;
}
.responsive-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dark-mode .responsive-table tbody tr:nth-child(even) {
  background-color: #272727;
}
.responsive-table tbody tr:hover {
  background-color: #f0f0f0;
}
.dark-mode .responsive-table tbody tr:hover {
  background-color: #272727;
}
.margin-top {
  margin-top: clamp(30px, 3rem, 50px);
}
.sortingParent {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sortingParent > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sortingIconSVG {
  width: 8px;
  height: auto;
  fill: #333;
}
.responsive-table th:hover .sortingIconSVG {
  fill: currentColor;
}
