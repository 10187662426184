.create-quiz-event-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.create-quiz-event-container label {
  display: block;
  margin-bottom: 8px;
}

.create-quiz-event-container input,
.create-quiz-event-container textarea {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-quiz-event-container textarea {
  resize: vertical;
}
.dark-mode .create-quiz-event-container input,
.dark-mode .create-quiz-event-container textarea {
  background-color: #272727;
  color: white;
  outline: none;
  border-color: #272727;
}
.dark-mode .ReactModalPortal > div {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
